<template>
    <div class="project-item" :class="{ spread: isSpread }">
        <div class="project-item-title">
            <a
                target="_blank"
                :title="project.name ? project.name.replaceAll('$#$', '-') : ''"
                class="project-title-link text-ellipsis"
                :href="`/Headhunting/MyCompany.html#/ProjectDetail/${project.id}`"
            >
                <!-- {{ project.name }} -->
                {{ itemProjectName }}
            </a>
            <span class="project-location" :title="project.location">
                [<i>{{ project.location }}</i
                >]
            </span>
        </div>
        <div class="project-item-info">
            <div class="project-item-main">
                <p>
                    <span
                        class="item-text item-text-customer text-ellipsis"
                        :title="project.customerName"
                        >{{ project.customerName }}</span
                    ><i></i><span>需 {{ project.headcount }} 人</span>
                </p>
                <p>
                    <span class="item-text" :title="project.customerDeparmentName">
                        {{ project.customerDeparmentName }}
                    </span>
                    <!-- <i></i> -->
                    <!-- <span class="item-text" :title="project.jobSerieName">
                        {{ project.jobSerieName }}
                    </span> -->
                </p>
            </div>
            <div class="project-item-time">
                {{ project.created | createDateFilter }} 发布 /
                {{ project.updated | dateFilter }} 更新
            </div>
            <div class="project-item-publisher">
                <avatar
                    class="user-avatar"
                    size="sm"
                    :src="project.avatarUrl"
                    :userId="project.ownerId"
                    :enableCard="true"
                    :enableLink="true"
                ></avatar>
                <template v-if="!userInfo.isEmployer">
                    <div class="project-number">
                        <!-- <span title="抢单">
                            抢 {{job.orderCount}}
                        </span> -->
                        <span title="推荐">
                            推 {{ project.recommendCount }}
                        </span>
                        <span title="面试">
                            面 {{ project.interviewCount }}
                        </span>
                        <span title="offer">
                            offer {{ project.offerCount }}
                        </span>
                    </div>

                    <!-- <el-dropdown
                        placement="bottom"
                        :class="{'hidden': !(project.hasEditRight)}"
                        @command="handleJobMoreOperate">
                        <span
                            class="el-dropdown-link el-icon-more"
                            v-loading="editLoading"
                        ></span>
                        <el-dropdown-menu
                            class="job-more-opr-dropdown"
                            slot="dropdown">
                            <el-dropdown-item 
                                command="editProject" 
                                >编辑项目</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->

                    <div class="switch-wrap" v-if="jobList.length">
                        <a
                            class="draw-switch"
                            @click="switchDraw(0)"
                            v-if="isSpread"
                        >
                            收起职位
                            <i class="draw-icon up"></i>
                        </a>
                        <a
                            class="draw-switch"
                            v-if="!isSpread"
                            @click="switchDraw(1)"
                        >
                            展开职位
                            <i class="draw-icon"></i>
                        </a>
                    </div>
                </template>
            </div>
        </div>

        <div class="jobs-wrap" v-loading="listLoading">
            <template v-if="jobList.length">
                <job-item
                    v-for="(job, index) in jobList"
                    :key="'job_' + index"
                    :job="job"
                    :projectItemId="project.id"
                    @changeJobStatus="changeJobStatus"
                ></job-item>
            </template>
            <div class="load-more" v-show="showMore">
                <p @click="getMore">加载更多</p>
            </div>
            <!-- <div class="empty" v-if="jobList.length == 0">
                <span class="empty-img"></span>
                <p class="empty-data">暂无数据</p>
            </div> -->
        </div>
    </div>
</template>

<script>
import Avatar from "#/component/common/avatar.vue";
import moment from "moment";
import JobItem from "../../program-detail/component/job-item.vue";
import projectDetailService from "#/js/service/projectDetailService.js";

export default {
    components: {
        Avatar,
        JobItem,
    },
    props: {
        project: Object,
    },
    data() {
        return {
            itemProjectName: "",
            editLoading: false,
            isSpread: false, // 是否展开
            listLoading: false,
            jobList: [],
            sizeStep: 5,
            pager: {
                current: 1,
                take: 5,
                total: 0,
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        showMore() {
            return this.pager.total > this.pager.current * this.pager.take;
        },
    },
    filters: {
        createDateFilter(val) {
            let today = moment().format("YYYY-MM-DD"),
                dateDay = moment(val).format("YYYY-MM-DD");
            if (today === dateDay) {
                return "今天";
            } else if (
                moment().subtract(1, "days").format("YYYY-MM-DD") === dateDay
            ) {
                return "昨天";
            } else {
                return dateDay;
            }
        },
        dateFilter(val) {
            let now = new Date(),
                date = val;
            let today = moment().format("YYYY-MM-DD"),
                dateDay = moment(date).format("YYYY-MM-DD"),
                nowTime = new Date().getTime(),
                dateTime = new Date(date).getTime(),
                oneHourTime = 60 * 60 * 1000,
                hour = now.getHours(),
                dateHours = new Date(date).getHours();
            if (today === dateDay) {
                if (
                    dateTime > nowTime - oneHourTime * 4 &&
                    dateTime < nowTime - oneHourTime
                ) {
                    return hour - dateHours + "小时前";
                } else if (
                    dateHours == hour ||
                    (dateTime > nowTime && dateTime < nowTime + oneHourTime) ||
                    (dateTime < nowTime && dateTime > nowTime - oneHourTime)
                ) {
                    // 目标时间与当前时间
                    let minutesDiff = Math.floor((dateTime - nowTime) / 60000);
                    if (minutesDiff == 0 || minutesDiff == 1) {
                        return "刚刚";
                    } else if (minutesDiff < 0) {
                        return minutesDiff * -1 + "分钟前";
                    }
                } else {
                    return "今天";
                }
            } else if (
                moment().subtract(1, "days").format("YYYY-MM-DD") === dateDay
            ) {
                return "昨天";
            } else {
                return dateDay;
            }
        },
        formatAvatar(url) {
            if(NODE_ENV && (NODE_ENV == 'dev' || NODE_ENV == 'rc')) {
                url = url.replace('www.rc.liebide.com', 'portalapi.rc.liebide.com');
            }
            return url;
        }
    },
    created() {
        this.getProjectJobs();
        // this.itemProjectName = this.project.name;
        this.itemProjectName = this.project.name.replaceAll("$#$", "-");
    },
    methods: {
        handleJobMoreOperate(command) {
            switch (command) {
                case "editProject":
                    this.editLoading = true;
                    this.$emit("edit-project-cb", this.project.id);
                    break;
                default:
                    break;
            }
        },
        switchDraw(type) {
            if (type == 1 && this.jobList.length == 0) {
                this.getProjectJobs();
            }
            this.isSpread = type != 0;
        },
        changeJobStatus() {
            this.getProjectJobs();
        },
        // 点击加载更多
        getMore(){
            this.pager.take = this.pager.take + this.sizeStep;
            // this.pager.current++;
            this.getProjectJobs();
        },
        getProjectJobs() {
            const params = {
                start: (this.pager.current - 1) * this.pager.take,
                take: this.pager.take,
                projectId: this.project.id,
            };
            this.listLoading = true;
            projectDetailService
                .getProjectJobPage(params)
                .then((res) => {
                    this.jobList = res.list;
                    this.pager.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.hidden {
    visibility: hidden;
}
.project-item {
    min-height: 150px;
    height: auto;
    padding: 27px 10px 10px;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    &:first-child {
        border-top: 1px solid #eee;
    }

    &-title {
        font-size: 16px;
        color: #333;
        line-height: 36px;

        .project-title-link {
            display: inline-block;
            max-width: 460px;
            vertical-align: top;
        }

        .project-location i {
            display: inline-block;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: top;
        }
    }

    &-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        margin-top: 10px;
    }

    &-main {
        width: 270px;
        padding-right: 10px;
        color: #666;

        > p {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin: 0;

            &:last-child {
                margin-top: 12px;
            }

            .item-text {
                display: inline-block;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.item-text-customer {
                    max-width: 200px;
                }
            }

            i {
                display: inline-block;
                width: 1px;
                height: 19px;
                margin: 0 10px;
                background-color: #d3d3d3;
            }
        }
    }

    &-time {
        width: 250px;
        padding-right: 10px;
        color: #999;
    }

    &-publisher {
        // display: flex;
        color: #666;
        width: 200px;
        text-align: right;
        .user-avatar {
            display: inline-block;
            margin-right: 10px;
            margin-top: -6px;
            width: 30px;
            height: 30px;
            .avatar-sm {
                width: 28px;
                height: 28px;
            }
        }
        .project-number {
            display: inline-block;
            height: 30px;
            span {
                display: inline-block;
                min-width: 40px;
                margin-right: 5px;
                text-align: left;
            }
        }

        .el-dropdown {
            height: 30px;
            .el-dropdown-link {
                color: #ccc;
                display: inline-block;
                width: 60px;
                text-align: center;
                line-height: 20px;
                font-size: 26px;
            }
            .el-loading-spinner {
                top: 0;
            }
        }
        .switch-wrap {
            // display: inline-block;
            // width: 75px;
            width: 100%;
            text-align: right;
            margin-right: 16px;
            .draw-switch {
                font-size: 14px;
                font-weight: 400;
                color: #38bc9d;
                cursor: pointer;
                &:active,
                &:hover {
                    color: #38bc9d;
                }
                .draw-icon {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background-size: 100% 100%;
                    background: url("~@src/assets/images/program/show_more.png")
                        no-repeat;
                    margin-left: 1px;
                    vertical-align: middle;
                }
                .up {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .jobs-wrap {
        width: 100%;
        height: auto;
        // min-height: 200px;
        margin-top: 30px;
        display: none;
        background: white;
        .load-more {
            width: 100%;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            text-align: center;
            cursor: pointer;
        }
        // .empty {
        //     min-height: 200px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     flex-direction: column;
        //     .empty-img {
        //         display: inline-block;
        //         background: url("~@src/assets/images/home/icon_no_data.svg")
        //             no-repeat;
        //         background-size: contain;
        //         width: 170px;
        //         height: 160px;
        //     }
        //     .empty-data {
        //         font-size: 14px;
        //         margin-bottom: 0px;
        //     }
        // }

        .job-item {
            // background-color: #fff;
            // padding: 20px;
            // border-top: 1px solid #eee;
            // &:first-child{
            //     border-top: 1px solid transparent;
            // }
            // .text-ellipsis {
            //     overflow: hidden;
            //     white-space: nowrap;
            //     text-overflow: ellipsis;
            // }
            &:hover {
                background-color: #fcfcfc !important;
            }
        }
    }
}
.spread {
    background: #f6f6f6;
    .jobs-wrap {
        display: block;
    }
}
</style>