var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-item", class: { spread: _vm.isSpread } },
    [
      _c("div", { staticClass: "project-item-title" }, [
        _c(
          "a",
          {
            staticClass: "project-title-link text-ellipsis",
            attrs: {
              target: "_blank",
              title: _vm.project.name
                ? _vm.project.name.replaceAll("$#$", "-")
                : "",
              href: `/Headhunting/MyCompany.html#/ProjectDetail/${_vm.project.id}`,
            },
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.itemProjectName) + "\n        "
            ),
          ]
        ),
        _c(
          "span",
          {
            staticClass: "project-location",
            attrs: { title: _vm.project.location },
          },
          [
            _vm._v("\n            ["),
            _c("i", [_vm._v(_vm._s(_vm.project.location))]),
            _vm._v("]\n        "),
          ]
        ),
      ]),
      _c("div", { staticClass: "project-item-info" }, [
        _c("div", { staticClass: "project-item-main" }, [
          _c("p", [
            _c(
              "span",
              {
                staticClass: "item-text item-text-customer text-ellipsis",
                attrs: { title: _vm.project.customerName },
              },
              [_vm._v(_vm._s(_vm.project.customerName))]
            ),
            _c("i"),
            _c("span", [_vm._v("需 " + _vm._s(_vm.project.headcount) + " 人")]),
          ]),
          _c("p", [
            _c(
              "span",
              {
                staticClass: "item-text",
                attrs: { title: _vm.project.customerDeparmentName },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.project.customerDeparmentName) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "project-item-time" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("createDateFilter")(_vm.project.created)) +
              " 发布 /\n            " +
              _vm._s(_vm._f("dateFilter")(_vm.project.updated)) +
              " 更新\n        "
          ),
        ]),
        _c(
          "div",
          { staticClass: "project-item-publisher" },
          [
            _c("avatar", {
              staticClass: "user-avatar",
              attrs: {
                size: "sm",
                src: _vm.project.avatarUrl,
                userId: _vm.project.ownerId,
                enableCard: true,
                enableLink: true,
              },
            }),
            !_vm.userInfo.isEmployer
              ? [
                  _c("div", { staticClass: "project-number" }, [
                    _c("span", { attrs: { title: "推荐" } }, [
                      _vm._v(
                        "\n                        推 " +
                          _vm._s(_vm.project.recommendCount) +
                          "\n                    "
                      ),
                    ]),
                    _c("span", { attrs: { title: "面试" } }, [
                      _vm._v(
                        "\n                        面 " +
                          _vm._s(_vm.project.interviewCount) +
                          "\n                    "
                      ),
                    ]),
                    _c("span", { attrs: { title: "offer" } }, [
                      _vm._v(
                        "\n                        offer " +
                          _vm._s(_vm.project.offerCount) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _vm.jobList.length
                    ? _c("div", { staticClass: "switch-wrap" }, [
                        _vm.isSpread
                          ? _c(
                              "a",
                              {
                                staticClass: "draw-switch",
                                on: {
                                  click: function ($event) {
                                    return _vm.switchDraw(0)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        收起职位\n                        "
                                ),
                                _c("i", { staticClass: "draw-icon up" }),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isSpread
                          ? _c(
                              "a",
                              {
                                staticClass: "draw-switch",
                                on: {
                                  click: function ($event) {
                                    return _vm.switchDraw(1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        展开职位\n                        "
                                ),
                                _c("i", { staticClass: "draw-icon" }),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "jobs-wrap",
        },
        [
          _vm.jobList.length
            ? _vm._l(_vm.jobList, function (job, index) {
                return _c("job-item", {
                  key: "job_" + index,
                  attrs: { job: job, projectItemId: _vm.project.id },
                  on: { changeJobStatus: _vm.changeJobStatus },
                })
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMore,
                  expression: "showMore",
                },
              ],
              staticClass: "load-more",
            },
            [_c("p", { on: { click: _vm.getMore } }, [_vm._v("加载更多")])]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }